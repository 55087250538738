import { useState, useEffect } from "react";
import { Facebook, Globe, Instagram, Mail } from "lucide-react";

export default function ConstructionPage() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date("2025-08-15") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
        heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        secondes: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  function SocialBubble({ href, icon, label, bgColor, hoverColor }) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`group relative ${bgColor} hover:${hoverColor} text-grey-600 hover:text-white w-12 h-12 rounded-full flex items-center justify-center transition duration-300`}
        aria-label={label}
      >
        {icon}
        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-white text-gray-800 px-2 py-1 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
          {label}
        </span>
      </a>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center gradient-background text-white lg:overflow-hidden sm:overflow-auto">
      <img
        src="/supernana.png"
        alt="Illustration de Super Nana pour les RJV Savièse 2025"
        className="absolute left-0 top-1/2 transform -translate-y-1/2 h-40 lg:h-64 w-auto object-contain hidden lg:block"
      />

      <img
        src="/edd.png"
        alt="Illustration de Edd pour les RJV Savièse 2025"
        className="absolute right-0 top-1/2 transform -translate-y-1/2 h-40 lg:h-64 lg:w-96 w-auto object-contain hidden lg:block"
      />

      <div className="mt-0">
        <img
          src="/Logo.png"
          alt="Logo officiel des RJV Savièse 2025"
          className="mx-auto h-64 max-h-96 w-auto max-w-xs md:max-w-md object-contain"
        />
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4 items-center">
        <a
          href=""
          hidden
          className="hidden disable max-w-xs lg:max-w-m group relative bg-orange-600 hover:bg-orange-700 text-white font-bold text-xl md:text-2xl py-4 px-8 rounded-full shadow-lg transform transition-all duration-300 mb-4 lg:mb-0 border-4 border-white flex items-center justify-center"
        >
          Inscriptions Jeunesses
        </a>
        <a
          href="https://forms.gle/K7wzvdgTorLnUxPr9"
          className="max-w-xs lg:max-w-m group relative bg-orange-600 hover:bg-orange-700 text-white font-bold text-xl md:text-2xl py-4 px-8 rounded-full shadow-lg transform transition-all duration-300 border-4 border-white flex items-center justify-center"
        >
          S'inscrire comme Bénévole
        </a>
      </div>

      <div className="flex flex-wrap justify-center gap-4 mb-8 mt-8">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="flex flex-col items-center">
            <div className="w-32 h-32 flex flex-col items-center justify-center bg-white rounded-full border-4 shadow-lg transform transition-transform duration-200">
              <span className="text-3xl md:text-4xl font-bold text-orange-600">
                {value}
              </span>
              <span className="text-sm md:text-base text-black font-semibold mt-1">
                {unit}
              </span>
            </div>
          </div>
        ))}
      </div>

      <h1 className="text-4xl mb-4 text-white-600">Du 15 au 17 août 2025</h1>

      <h2 className="text-xl md:text-2xl mb-3 text-white-600">
        Notre site est actuellement en construction.
      </h2>

      <div className="flex justify-center space-x-4 mb-8">
        <SocialBubble
          href="https://jeunessesaviese.ch"
          icon={<Globe size={24} />}
          label="Jeunesse de Savièse"
          bgColor="bg-orange-500"
        />
        <SocialBubble
          href="https://www.instagram.com/rjv2025_saviese/"
          icon={<Instagram size={24} />}
          label="Instagram"
          bgColor="bg-pink-600"
        />
        <SocialBubble
          href="https://www.facebook.com/profile.php?id=100067419638062"
          icon={<Facebook size={24} />}
          label="Facebook"
          bgColor="bg-blue-500"
        />
        <SocialBubble
          href="mailto:rjv2025saviese@gmail.com"
          icon={<Mail size={24} />}
          label="Contact"
          bgColor="bg-red-500"
        />
      </div>
    </div>
  );
}
